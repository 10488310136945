/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "widex-dream-product-review",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#widex-dream-product-review",
    "aria-label": "widex dream product review permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Widex Dream product review"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Widex Dream ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " combine the latest features and proven designs to offer a great hearing experience. For example, their True-Input technology offers sound input up to 113 dB SPL and delivers true-to-life sound to let you clearly distinguish words and other environmental sounds. This allows you to experience the full sound picture in your surrounding environment. Widex Dream also delivers excellent speech quality, even in the noisiest situations. Indeed, Dream users report up to 21% better speech recognition in loud noise environments."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Battery life is an important part of the user experience and The Widex Dream battery has been improved to provide 20% more lifetime, even when streaming your favorite music. Ease of use is further enhanced with features that are quickly accessed the moment you need them and even the battery drawer has been improved, with better grip and easy opening to facilitate simple battery changes. The quality of your user experience is maximized with customization features, such as a choice of six standard colors and eight additional available colors with your Dream hearing aid."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "let-the-music-play",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#let-the-music-play",
    "aria-label": "let the music play permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Let the Music Play"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Listening to music can be one of life’s great pleasures, and with the Widex Dream, it is one you can enjoy in full. In order to experience the full impact of your favorite music, however, you need to be able to hear all of the subtleties and nuances. That is all down to the way your hearing aid deals with sound when it enters the unit. This is known as input handling. Some hearing aids will compress or clip notes at certain volume levels, but the Widex Dream is different. The Widex Dream uses True-Input technology to dramatically widen the input range of the music you’re listening to. This creates a sound picture that is much more true to life and lets you hear all the detail in your music."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "the-dream-cic-micro",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#the-dream-cic-micro",
    "aria-label": "the dream cic micro permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "The Dream CIC Micro"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With the Dream CIC Micro, tiny size doesn’t have to mean tiny sound. Its onboard technology means that normally difficult and noisy situations, such as sporting arenas and restaurants, are now easy to hear in and enjoyable to be a part of. The Dream CIC Micro is also great for new users. It has a Personal Acclimatization feature, which makes it easy for you to adapt to the new, clear sounds that you will hear. It’s also simple to use and requires no adjustments at all while being used. In fact, after you are fitted for the Dream CIC Micro, you only need to switch it on and then forget you’re wearing it. It really is that simple."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "dream-fashion-power",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#dream-fashion-power",
    "aria-label": "dream fashion power permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Dream Fashion Power"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you happen to have hearing loss that is severe-to-profound, then you need a hearing aid that benefits from having a lot of power. It should be remembered, however, that lots of power on its own does not guarantee the best sound quality. The Dream Fashion Power hearing aid delivers both the power you need and the sound quality you expect. Using the latest technology, it delivers outstanding sound quality in even the most challenging environments. You will enjoy richer sound, free from distortion and be able to hear the smallest detail in the environment around you. Even in the noisiest environments, conversations are heard with fantastic clarity in true to life, natural sound. Many will think that accessing such hearing power requires lots of batteries, but this isn’t the case with the Dream Fashion Power hearing aid. In fact, the clever PowerSaver feature allows you to enjoy long periods of clear hearing without changing the batteries."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "customize-your-dream-hearing-aid",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#customize-your-dream-hearing-aid",
    "aria-label": "customize your dream hearing aid permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Customize Your Dream Hearing Aid"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Widex range of DEX accessories makes it easy for you to use your TV, phone and audio devices. Your Dream hearing (except for the Dream CIC Micro) is compatible with a huge range of useful accessories. Com-Dex, for example, is a hands-free device designed to keep you in control and connected. Call-Dex, meanwhile, allows for the most discreet streaming from your mobile phone. Uni-Dex provides plug and play mobile phone use and FM-Dex is great for FM radio streaming. RC-Dex puts you in charge of your remote control and TV-Dex helps you get the most from your TV. Phone-Dex makes it easy to use your landline."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "As you can see, Widex Dream hearing aids have lots of excellent features and some great models to choose from. It’s vital that you select the hearing aid that is right for you – and that is why we offer a free telephone consultation to help you make the right choice. You will then be referred to a partner specialist in your area for a fitting and fine-tuning of your perfect hearing aid. Our 45-day trial will ensure you enjoy the best possible hearing with your new device."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Widex Dream now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
